<template>
    <!--    <phone-input-->
    <!--        @phone="phone = $event"-->
    <!--        @country="country = $event"-->
    <!--        @phoneData="phoneData = $event"-->
    <!--        class="mt-1 block w-full px-0 app-phone-field"-->
    <!--        :arrow="false"-->
    <!--        defaultCountry="US"-->
    <!--        :allowed="[ 'US', 'CA' ]"-->
    <!--        :value="model?.replace('+', '')"-->
    <!--    />-->

    <div class="flex items-center gap-2">
        <div class="text-sm font-bold">
            {{ countryCode }}
        </div>
        <TextInput
            v-model="localModel"
            class="block w-full"
            required
            autocomplete="phone"
        />
    </div>
</template>
<script setup>
import {ref, watch} from 'vue';
import TextInput from "@/Components/TextInput.vue";
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js/min'

const localModel = ref('');
const countryCode = ref('+1');
const phoneData = ref('');

const model = defineModel()

watch(localModel, (value) => {
    const data = parsePhoneNumber(value, 'US');

    localModel.value = new AsYouType('US').input(value)

    model.value = data.number;
});

</script>
